/*Components*/
.btn {
    @include btn-small();

    @include breakpoint(md) {
        @include btn-default();
    }

    &--small {
        @include btn-small();
    }

    &--default {
        @include btn-default();
    }

    &--large {
        @include btn-larger();
    }

    //styles
    &--light {
        --color: var(--base-color);
        --background: var(--neutral-dark);
        --border-color: var(--neutral-dark);
        color: var(--color) !important;
        background: var(--background);
        border: 2px solid var(--border-color);

        &:hover,
        &:focus,
        &:active {
            --color: var(--lightest-color);
            --background: var(--lightest-color);
            --border-color: var(--primary-color);
        }
    }

    &[disabled] {
        opacity: .5;
        cursor: not-allowed;
    }

    &-group__item {
        --border-radius: 0;
        --color: var(--base-color);
        --background: var(--lightest-color);
        --border-color: var(--neutral-dark);
        --border-left-width: 0;
        --border-right-width: 0;
        color: var(--color) !important;
        background: var(--background);
        border-style: solid;
        border-width: 1px var(--border-right-width) 1px 1px;
        border-color: var(--border-color);

        &,
        &:hover,
        &:active,
        &:focus {
            border-radius: var(--border-radius);
        }

        &:first-child {
            --border-radius: var(--border-radius-default) 0 0 var(--border-radius-default);
        }

        &:last-child {
            --border-right-width: 1px;
            --border-radius: 0 var(--border-radius-default) var(--border-radius-default) 0;
        }

        &.active {
            --color: var(--lightest-color);
            --background: var(--primary-color);
        }

    }
}

.rect {
    background-color: $neutral-dark;
    color: $neutral-darkest;
    padding: 5px 10px;
    border-radius: 4px;
    display: inline-block;

    a {
        color: $primary-color;
        text-decoration: underline;
    }
}

li.errorCount {
    padding-right: 25px !important;
    nz-badge {
        margin-left: 10px;
        position: absolute;
        right: 15px;
        margin-top: 2px;
        p {
            line-height: 1.4;
        }
    }
}

p.image-info {
    font-size: 12px !important;
}

.font-weight-bold {
    font-family: $bold-font-family !important;
}

.font-size-med {
    font-size: 24px !important;
}

.loader {
    width: 100%;
    position: absolute;
    text-align: center;
    font-size: 80px;
    height: 100%;
    min-height: 100px;
    z-index: 1;
    background-color: rgba(229, 227, 223, 0.45);

    i {
        position: relative;
        top: calc(50% - 80px);
    }
}

.error {
    color: rgb(226, 0, 108);
}

p {
    &.error {
        @extend .error
    }
}

.cta_link {

    @include font-normal($primary-font-family , $warning-color);
    line-height: inherit;

    &-password {
        font-family: $bold-font-family;
    }

    &:hover {
        color: white;
    }
}

.eye_icon {
    &:hover {
        cursor: pointer;
    }
}