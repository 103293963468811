/* This is the rules of overwrites for ngx-quill richtext component */
.quill-editor-edit {
    border-radius: 4px !important;
    border: 2px solid #e5e3df !important;
    background: white !important;

    &:not(.ql-disabled):focus-within {
        .ql-toolbar.ql-snow {
            border: 0px !important;
        }

        .ql-container.ql-snow {
            border: 0px !important;
            border-top: 1px solid #e5e3df !important;
        }

        border: 2px solid #1403cd !important;
        box-shadow: none !important;
        outline: none !important;
    }

    #description {
        display: block;
        // border: 2px solid #e5e3df;
        border-radius: 4px !important;
    }

    .ql-toolbar.ql-snow {
        background: white !important;
        border-bottom: 0px transparent !important;
        border-radius: 4px 4px 0px 0px;
        border-color: transparent;
    }

    .ql-container.ql-snow {
        background: white !important;
        border-top: 1px solid #e5e3df !important;
        border-radius: 0px 0px 4px 4px;
        border: unset;
    }

    .ql-toolbar.ql-snow .ql-formats {
        margin-right: 10px;
    }

    .ql-snow .ql-picker.ql-size {
        width: 75px;
        margin-bottom: 10px;
    }

    &.ql-disabled button {
        pointer-events: none !important;
    }

    &.ql-disabled .ql-picker-label {
        cursor: not-allowed !important;
        pointer-events: none !important;
    }

    &.ql-disabled .ql-editor {
        cursor: not-allowed !important;
    }

    &.ql-disabled .ql-editor * {
        cursor: not-allowed !important;
        pointer-events: none !important;
    }
}

.ql-container.ql-snow.ql-disabled {
    cursor: not-allowed !important;

    p {
        pointer-events: none !important;
    }
}

.container-fluid.bg-neutral {
    min-height: 500px;
}

/*This is the stylesheet of overwrites from Ng components*/
.anticon.anticon-down svg {
    //Arrow down ng-ant
    background: url('/assets/images/icon-chevron-down.svg') 54% -5px no-repeat #fff;
}

.anticon.anticon-up svg {
    //Arrow up ng-ant
    background: url('/assets/images/icon-chevron-up.svg') 54% -5px no-repeat #fff;
}

.ant-form-item {
    @include font-normal(normal, $base-color);
}

/*Selects*/
.ant-select-selection {
    display: flex;
    align-items: center;
    border: 2px solid #e5e3df;
    border-top-width: 2px;
}

.ant-select-selection--single {
    @include font-normal(normal, $base-color);
    min-height: $input-small-height; //overwrite

    .ant-select-selection__rendered {
        width: 90%;
    }

    @include breakpoint(md) {
        min-height: $input-default-height; //overwrite ng-ant
    }
}

.ant-select-search--inline .ant-select-search__field {
    padding: 0px;
    border: none !important;

    &:focus {
        box-shadow: none !important;
    }
}

#artists .ant-select-search__field {
    height: 30px;
    padding-left: 2px;
    transition: none;
}

.ant-select-selection--multiple .ant-select-selection__choice {
    background-color: #e5e3df;
    border: none;
    border-radius: 4px;
    margin: 2px !important;
    @include font-normal(normal, $base-color);
}

.ant-select-selection--multiple {
    min-height: $input-small-height;

    @include breakpoint(md) {
        min-height: $input-default-height;
    }
}

.ant-select-selection-selected-value {
    line-height: 30px;
}

.ant-select-selection:hover {
    border-color: $neutral-dark;
    border-right-width: 2px !important;
}

.ant-select-focused .ant-select-selection,
.ant-select-selection:active,
.ant-select-selection:focus {
    border-color: $primary-color;
    border-right-width: 2px !important;
    box-shadow: none !important;
}

.ant-select-open .ant-select-selection {
    border: 2px solid $primary-color-dark; //overwrite ng-ant
}

.ant-select-selection--multiple .ant-select-selection__rendered>ul>li,
.ant-select-selection--multiple>ul>li {

    // margin-top: 5.25px;
    @include breakpoint(md) {
        height: 31px;
        line-height: 31px;
    }
}

.ant-select-selection--multiple .ant-select-arrow,
.ant-select-selection--multiple .ant-select-selection__clear {
    top: 50%;
}

.anticon.ant-select-remove-icon.anticon-close {
    background: url('/assets/images/icon-x.svg') 54% -4px no-repeat;
}

.ant-select-selection--multiple .ant-select-selection__choice__remove svg {
    opacity: 0;
}

.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected .ant-select-selected-icon,
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected:hover .ant-select-selected-icon {
    color: $base-color;
}

/*Checkbox [nz-checkbox] */
.ant-checkbox-inner {
    border: 2px solid $neutral-dark;
    height: 20px;
    width: 20px;
}

.ant-checkbox-input:focus+.ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
    border-color: $primary-color-dark;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: $primary-color;
    border: 2px solid $primary-color;
}

.ant-checkbox-checked .ant-checkbox-input:focus+.ant-checkbox-inner {
    background-color: $primary-color-dark;
}

.ant-checkbox-inner:after {
    background: url('/assets/images/icon-checkmark-white.svg') no-repeat;
    background-position: center center;
}

.ant-checkbox-checked .ant-checkbox-inner:after {
    border: none;
    height: 20px;
    left: -1.5px;
    top: -2px;
    transform: none;
    -webkit-transform: none;
    width: 20px;
}

.ant-checkbox+span,
.ant-checkbox-wrapper+span {
    @include font-bold($base-color);
    margin-top: -5px !important;
}

#activityAccesRestrictions .ant-checkbox+span,
#activityAccesRestrictions .ant-checkbox-wrapper+span {
    @include font-normal(normal, $base-color);
    font-size: 14px !important;
    margin-top: -5px !important;
}

.d-flex .ant-checkbox {
    display: table !important;
}

.ant-radio-group {
    margin: 0 auto;

    .ant-radio-button-wrapper,
    .ant-radio-wrapper {
        &:hover {
            color: $primary-color;
        }

        display: block;
    }

    .ant-radio-button-wrapper-checked,
    .ant-radio.wrapper-checked {
        background: $primary-color;
        color: $lightest-color !important;
        border-color: $primary-color;

        &:hover {
            color: $lightest-color;
        }
    }
}

// PROGRAMS
.program-calendar .ant-fullcalendar-header {
    display: none !important;
}

.program-calendar .ant-fullcalendar-calendar-body {
    padding: 8px 0px;
}

#activityAccesRestrictions .ant-radio+span,
#activityAccesRestrictions .ant-radio-checked+span {
    @include font-normal(normal, $base-color);
    font-size: 14px !important;
    margin-top: -5px !important;
}

/*Input*/
.ant-input {
    border: 2px solid $neutral-dark;
    line-height: $input-small-height;
    min-height: #{$input-small-height}em !important;
    color: $base-color;

    @include breakpoint(md) {
        min-height: $input-default-height !important;
    }
}

.ant-input:focus {
    box-shadow: none !important;
    border-right-width: 2px !important;
}

.ant-input:focus,
.ant-input:hover {
    border-color: $primary-color !important;
}

.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled),
.ant-input-group.ant-input-group-compact>.ant-calendar-picker:last-child .ant-input:hover,
.ant-input-group.ant-input-group-compact>.ant-cascader-picker-focused:last-child .ant-input:hover,
.ant-input-group.ant-input-group-compact>.ant-cascader-picker:last-child .ant-input:hover,
.ant-input-group.ant-input-group-compact>.ant-mention-wrapper:last-child .ant-mention-editor:hover,
.ant-input-group.ant-input-group-compact>.ant-select-auto-complete:last-child .ant-input:hover,
.ant-input-group.ant-input-group-compact>.ant-select:last-child>.ant-select-selection:hover,
.ant-input-group.ant-input-group-compact>.ant-time-picker:last-child .ant-time-picker-input:hover,
.ant-input-group.ant-input-group-compact> :last-child:hover {
    border-color: $primary-color !important;
    border-right-width: 2px !important;
}

.ant-input-affix-wrapper .ant-input:not(:first-child) {
    min-height: $input-default-height;
    padding-left: 35px;
}

.ant-input-affix-wrapper .ant-input-prefix :not(.anticon),
.ant-input-affix-wrapper .ant-input-suffix :not(.anticon) {
    //icon
    font-size: 20px;
}

.ant-input-disabled {
    //disabled
    background-color: var(--darker-grey) !important;
    color: rgba(0, 0, 0, 0.4);
    pointer-events: none !important;
}

.ant-form-item-label.label-only>label::after {
    content: '';
}

.label-with-icon label {
    display: flex;
    align-items: center;
}

/*Form*/
.ant-form-item-control {
    line-height: 35px;
}

.has-error {
    color: #e2006c !important;
    font-family: var(--primary-font-family) !important;
    font-size: 13px !important;
    font-weight: normal;
    animation: fadeIn 1s;
}

.ant-form-explain,
.ant-form-split {
    margin-top: 7.5px;
}

.ant-form-explain,
p {
    &.has-error {
        @include font-small(normal, $error-color);
        margin-bottom: 8px;
    }
}

form textarea.ant-input {
    margin-bottom: 0px;
}

.ant-btn-group .ant-btn {
    color: $base-color;

    &:hover,
    &:focus {
        color: $base-color;
    }
}

/*Button*/
.ant-btn {
    line-height: unset;
    @include font-small(normal, $lightest-color);
    border-radius: 4px;

    &:hover,
    &:focus {
        border-color: $dark-grey;
        color: $lightest-color;
    }

    &.ant-btn-primary {
        background: $primary-color;
        border-color: $primary-color;
        color: $lightest-color;
    }

    &[disabled] {
        background-color: $neutral-darker;
        color: $neutral-darkest;
    }
}

.ant-modal-footer .ant-btn-default {
    color: $neutral-darkest !important;
}

/*Notifications*/
.ant-notification {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    width: 764px;
}

.rideau-notify {
    border-radius: 6px;

    &.ant-notification-notice {
        display: flex;
        padding: 33px 40px;
        align-items: center;
        justify-content: start;
        min-width: 384px;
        width: auto;

        .ant-notification-notice-content .ant-notification-notice-with-icon {
            display: flex;
            align-items: center;
        }
    }

    &.rideau-notify--success {
        border: solid 2px $success-color;
    }

    &.rideau-notify--warning {
        border: solid 2px $warning-color;
    }

    &.rideau-notify--error {
        border: solid 2px $error-color;
    }
}

.ant-notification-notice {
    font-size: 16px;
    font-weight: 900;
    text-align: left;
    color: $base-color;
    padding: 0px;
    position: relative;

    a.ant-notification-notice-close {
        top: 50%;
        right: 30px;
        position: absolute;
        transform: translateY(-50%);
        color: rgba(0, 0, 0, .85);

        span i {
            font-size: 16px;
            color: $base-color;
        }
    }
}

.ant-notification-notice-content.ant-notification-notice-with-icon .ant-notification-notice-with-icon {
    display: flex;
    border-radius: 6px;
    padding: 33px 40px;
    align-items: center;

    .anticon {
        margin-right: 35px;
        font-size: 35px !important;

        &.ant-notification-notice-icon-success {
            color: $success-color;
        }

        &.ant-notification-notice-icon-error {
            color: $error-color;
        }
    }

    .ant-notification-notice-message {
        font-size: 16px;
        line-height: 21px;
        color: $base-color;
        margin-left: 68px;
        margin-bottom: 0px;
    }
}

//.ant-message-custom-content.ant-message-success {
//  border: solid 2px $success-color;
//  .anticon {
//    color: $success-color;
//  }
//}
//
//.ant-message-custom-content.ant-message-error {
//  border: solid 2px $error-color;
//  .anticon {
//    color: $error-color;
//  }
//}

/*Progress*/
.ant-steps-item-icon {
    border: none;
    border-radius: 0px;
}

.ant-steps-item-finish .ant-steps-item-icon {
    background: url('/assets/images/icon-checkmark.svg') 54% 3px no-repeat;
}

.ant-steps-item-description {
    @include font-bold($base-color);
}

.ant-steps-item-process>.ant-steps-item-content>.ant-steps-item-description {
    color: $primary-color;
}

.ant-steps-item-process .ant-steps-item-icon>.ant-steps-icon {
    @include font-bold($primary-color);
}

.ant-steps-item-wait .ant-steps-item-icon {
    @include font-bold($base-color);
    background: none;
}

.ant-steps-icon svg {
    opacity: 0;
}

.ant-steps-vertical .ant-steps-item-icon {
    bottom: 3px;
    margin-right: 6px;
    position: relative;
}

.ant-steps-vertical>.ant-steps-item:not(:last-child)>.ant-steps-item-tail {
    display: none;
}

.ant-steps-item-process .ant-steps-item-icon {
    background: none;
    border: 2px solid $primary-color;
    border-radius: 32px;
}

.ant-steps-item-icon>.ant-steps-icon {
    top: -3px;
}

.ant-steps-item-wait>.ant-steps-item-content>.ant-steps-item-description,
.ant-steps-item-wait>.ant-steps-item-content>.ant-steps-item-title {
    color: rgba(0, 0, 0, 0.25);
}

.ant-steps-item-finish>.ant-steps-item-content>.ant-steps-item-description {
    color: $base-color;
}

/*Table*/
.ant-table-tbody>tr>td {
    padding: 0px;
}

.ant-table-selection-down.ant-dropdown-trigger {
    background: url(/assets/images/icon-chevron-down.svg) no-repeat;
    background-position: left;
    margin-left: 8px;
    width: 20px;

    svg {
        opacity: 0;
    }
}

.ant-table-tbody>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td,
.ant-table-tbody>tr:hover:not(.ant-table-expanded-row)>td,
.ant-table-thead>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td,
.ant-table-thead>tr:hover:not(.ant-table-expanded-row)>td {
    background: $neutral-dark;
}

.ant-checkbox-indeterminate .ant-checkbox-inner:after {
    background: $primary-color;
}

/*Pagination*/
.ant-pagination {
    display: flex;
    align-items: center;
    padding-top: 35px;
    padding-bottom: 30px;
}

.ant-table-pagination.ant-pagination {
    float: left;
    padding-left: 15px;
    @include font-bold($base-color);
}

.ant-pagination-item {
    border: none !important;
    background: none;
    @include font-bold($base-color);
}

.ant-pagination-item a {
    padding: 5px 11px;
}

.ant-pagination-item-active {
    border: none !important;
    background: blue;

    .ng-star-inserted {
        color: white;
    }
}

.ant-pagination-item-active a,
.ant-pagination-item:focus a,
.ant-pagination-item:hover a {
    color: white !important;
}

.ant-pagination-next .ant-pagination-item-link,
.ant-pagination-prev .ant-pagination-item-link {
    border: none;
}

.ant-pagination-disabled {
    color: currentColor;
    cursor: not-allowed;
    opacity: 0.5;
    text-decoration: none;
    pointer-events: none;
}

/*Menu vertical [nz-menu] */
.ant-menu {
    background: none;
    border-right: 0px;

    &-item {
        font-family: $bold-font-family;
        color: $base-color;
        margin: 0px !important; //overwrite ng ant
        outline: none;

        &-selected {
            background: none !important;
        }
    }

    .active {
        color: $primary-color;
    }

    &__count {
        @include font-small(normal, $neutral-darker);
    }

    &__label {
        font-family: $bold-font-family !important;
        margin-bottom: 15px;
    }

    .ant-menu-item:active,
    .ant-menu-submenu-title:active {
        background: none;
    }

    .ant-menu-item-selected,
    .ant-menu-item-selected>a,
    .ant-menu-item-selected>a:hover {
        color: $primary-color;
        outline: none;
    }

    .ant-menu-item-active,
    .ant-menu-item:hover,
    .ant-menu-submenu-active,
    .ant-menu-submenu-title:hover,
    .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open {
        color: $primary-color;
    }
}

.ant-menu-vertical>.ant-menu-item {
    height: 35px !important;
    line-height: 1.7;
}

/*Modal*/

/* PRINT */

@media print {
    .ant-modal-wrap {
        overflow: visible;
    }

    .ant-modal {
        width: 100% !important;
        max-width: 2480px;
        height: 100%;
        top: 0;
        bottom: 0;
        padding: 0;

        &-content {
            height: 100%;
            box-shadow: none;
        }
    }

    .ant-modal-header,
    .ant-modal-close,
    .ant-modal-footer {
        display: none;
    }
}

.ant-modal.customModal .ant-modal-content {
    background: #fff !important;

    .ant-btn-default {
        color: $primary-color;
    }
}

.ant-modal-confirm-content {
    color: $base-color !important;
    @include font-normal(normal, $base-color);
}

.ant-modal-confirm .ant-modal-body {
    border-radius: 6px;
    background-color: $lightest-color;
    border: 2px solid $neutral-dark;
    padding: 0px;
}

.ant-modal-confirm-body {
    padding: 20px;
}

.ant-modal-confirm-btns {
    background: $neutral-light;
    border-top: 2px solid $neutral-dark;
    display: flex;
    justify-content: flex-end;
    margin-top: 0px !important;
    padding: 15px;
    width: 100%;

    .ant-btn {
        color: $base-color !important;
    }

    .ant-btn-primary {
        color: $lightest-color !important;
    }
}

.ant-modal-confirm-title {
    @include font-large(normal, $base-color);
}

/*Avatar*/
.avatar-upload {
    position: relative;

    .avatar {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 0;
        transform: translate(-50%, -50%);
        padding: 8px; //same value as nz-upload
    }

    &--before:hover {
        &:before {
            opacity: 1;
        }

        .avatar-upload__remove {
            opacity: 1;
        }
    }

    &--before:before {
        background: rgba(0, 0, 0, 0.6);
        content: '';
        display: block;
        height: 100%;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        transition: 0.15s ease-in;
        width: 100%;
        z-index: 1;
    }

    &__remove {
        color: $lightest-color;
        font-size: 27.5px;
        left: 50%;
        opacity: 0;
        position: absolute;
        top: 50%;
        width: 100%;
        padding: 20px;
        transform: translate(-50%, -50%);
        transition: 0.15s ease-in;
        z-index: 2;
    }
}

.avatar-uploader {
    .avatar {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 0;
        transform: translate(-50%, -50%);
        padding: 8px; //same value as nz-upload
    }
}

.ant-upload {
    position: relative;

    .avatar {
        object-fit: cover;
        max-width: 100%;
        max-height: 100px;
    }
}

.avatar.w-50 {
    height: auto;
    object-fit: cover;
}

/* Show lateral bar*/
.showSingle-lateral {
    .ant-dropdown-menu {
        padding: 20px 0;
    }

    .ant-dropdown-menu-item {
        padding: 0;

        &:hover {
            background-color: #fff;
        }

        &:first-child {
            pointer-events: none;
            padding: 0 20px 20px 20px;
            border-bottom: 1px solid #e5e3df;
        }

        &:last-child {
            padding: 20px;
            padding-bottom: 0;
            pointer-events: none;

            button {
                pointer-events: initial;
            }
        }

        &.controls {
            display: flex;
            align-items: center;
            justify-content: center;

            .btn {
                height: auto !important;

                &.btn--light {
                    &:hover {
                        color: $base-color !important;
                    }
                }
            }
        }
    }

    .ant-checkbox-group {
        display: flex;
        flex-direction: column;

        & ::ng-deep .ant-checkbox-group-item {
            margin-right: 0;
            padding: 15px 20px;
            border-bottom: 1px solid #e5e3df;

            .ant-checkbox {
                .ant-checkbox-inner {
                    height: 28px;
                    width: 28px;

                    &:after {
                        height: 28px;
                        width: 28px;
                    }
                }
            }
        }
    }
}

/* File Input */
.file-upload {
    &__item {
        justify-content: flex-end;
        padding-right: 15px;
    }

    &__remove {
        width: auto;
        padding-left: 12.5px;
        padding-right: 12.5px;
        min-height: 40px;

        i {
            font-size: 20px;
        }
    }

    .ant-upload-list-item-info,
    .anticon-close {
        display: none;
    }
}

.ant-upload.ant-upload-select-picture-card:hover {
    border-color: $neutral-dark;
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
    background-color: #f8f8f8;
}

.ant-spin-dot-item {
    background-color: #1702f8;
}

.ant-progress-bg {
    background-color: #1702f8;
}

nz-badge p {
    @include font-normal(normal, $lightest-color);
}

nz-dropdown {
    button.ant-btn {
        color: $base-color !important;
        border: 2px solid $neutral-dark;
        height: 40px;
        font-size: 16px !important;
        margin-top: 5px;

        &:hover {
            border: 2px solid $primary-color;
            color: $base-color !important;
        }
    }
}

// Ant calendar
.ant-fullcalendar td {
    vertical-align: top;
    background: white !important;
    border: 2px solid $background-lighter-gray;
    position: static;
}

.ant-fullcalendar-fullscreen {
    .ant-fullcalendar-selected-day .ant-fullcalendar-date {
        background: none !important;
        border-top: none;
    }

    .ant-fullcalendar-date {
        margin: 0;
        padding: 0;
        height: auto;
        border-top: none;

        &:hover {
            cursor: default;
            background: none;
        }
    }

    .ant-fullcalendar-content {
        bottom: 0;
        min-height: 80px;
        height: auto;
        position: relative;
        overflow: visible;
    }

    .ant-fullcalendar-value {
        padding: 4px 10px 0 0;
    }
}

.ant-checkbox-checked::after {
    border: none;
}

app-password-input {
    width: 100%;
}

.ant-input-suffix {
    display: flex;
    align-items: center;
    left: 93%;
    position: relative !important;

    @media (min-width: 992px) {
        left: 90%;
    }
}

.ant-form-item-has-error .ant-select:not(.ant-select-borderless) .ant-select-selector {
    /* border-color: #ff4d4f !important; */
    border: 2px solid rgb(226, 0, 108) !important;
}

.ant-list-split {
    width: 100%;
}

.ant-select-selector {
    min-height: fit-content !important;
    height: 45px !important;
    justify-content: flex-start !important;
    align-items: center;
    color: #1c1c1b !important;
    font-family: var(--primary-font-family) !important;
    font-size: 14px !important;
    line-height: 1.6;
    letter-spacing: 0;
    font-weight: normal;
    border-radius: 5px !important;
    border: 2px solid #e5e3df !important;
}

nz-date-picker {
    height: 45px;
    border-radius: 5px !important;
    border: 2px solid #e5e3df !important;
    display: flex !important;

    input {
        border: 0 !important;

        &:focus {
            border: unset !important;
            box-shadow: none !important;
        }
    }
}

.ant-select-arrow {
    --right-position: 12px;
    right: var(--right-position) !important;

    .select--small & {
        --right-position: 15%;
    }
}

.ant-btn {
    color: var(--neutral-darkest) !important;
}

.ant-btn-primary {
    color: #ffffff !important;
}

.ant-picker {
    height: 45px;
    border: 2px solid #e5e3df !important;
    border-radius: 4px !important;
}

.ant-picker-range {
    border: 2px solid #e5e3df !important;
    border-radius: 4px !important;

    .ant-picker-input>input {
        border: 0 !important;
        padding: 5px 5px 5px 40px;

        &:focus {
            border: 2px solid $primary-color !important;
            box-shadow: none !important;
        }

        &:hover {
            border: none !important;
            box-shadow: none !important;
        }
    }

    .ant-picker-active-bar {
        display: none !important;
    }

}

.ant-picker-range:focus-within,
.ant-picker-range:hover {
    border: 2px solid $primary-color !important;
    box-shadow: none !important;
}

.ant-select-selection-search-input {
    border: 0 !important;
}

date-range-popup {
    .ant-picker-today-btn {
        color: #1c1c1b;

        &:hover {
            color: #1702f8;
        }
    }

    .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
        background: #1890ff !important;
        color: #fff !important;
    }

    .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner {
        color: #1890ff;
        font-weight: 700;
    }

    .ant-picker-cell-in-view .ant-picker-cell-inner:hover {
        background: #e6f7ff !important;
        color: rgba(0, 0, 0, .85) !important;
    }
}

.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    color: #fff !important;
}

.ant-notification-notice-with-icon .ant-notification-notice-message {
    transform: translateY(2px);
    padding-right: unset;
    padding-left: 20px;
}

.anticon.ant-notification-notice-icon-success {
    color: rgb(0, 184, 153);
    font-size: 40px;
}

.ant-notification-notice {
    align-items: center;
}


.ant-notification-notice-icon {
    font-size: 29px;
}

.ant-switch {
    min-width: 44px;
    height: 22px;

    .ant-switch-handle {

        &::before {
            border-radius: 50%;
        }
    }

}

/** ====== .ant-picker-calendar ====== **/
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected {

    &,
    &:hover {
        .ant-picker-calendar-date-value {
            color: var(--primary-color);
        }

        .ant-picker-calendar-date,
        .ant-picker-cell-inner {
            background: var(--lightest-color) !important;
        }
    }
}

.anticon.ant-notification-notice-icon-success {
    color: rgb(0, 184, 153);
}

.ant-notification-notice {
    align-items: center;
}

.ant-notification-notice-icon {
    font-size: 29px;
}

.ant-switch {
    min-width: 44px;
    height: 22px;

    .ant-switch-handle {

        &::before {
            border-radius: 50%;
        }
    }

    &.ant-switch-checked .ant-switch-handle {
        right: 10px;
        left: auto;
    }

    &-small {
        min-width: 33px;
        height: 21px;

        .ant-switch-handle {
            height: 17px;
            width: 17px;
            left: 2px;
        }

        &.ant-switch-checked .ant-switch-handle {
            right: 2px;
            left: auto;
        }
    }
}

/* ========= radio-button ========== */
.ant-radio-button-wrapper {
    --border-radius: 4px;

    &:last-child {
        border-radius: 0 var(--border-radius) var(--border-radius) 0;
    }

    &:first-child {
        border-radius: var(--border-radius) 0 0 var(--border-radius);
    }
}

.ant-select-item.ant-select-item-option-active {
    background-color: #e6f7ff !important;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
    padding: 0;
}

.ant-checkbox-wrapper {
    align-items: end;
}



.ant-select-selection-search-input {
    border: 0 !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #1702f8 !important;
}

.ant-picker-time-panel>.ant-picker-footer {
    display: none !important;
}

.ant-picker-time-panel-column>li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
    background: #e6f7ff !important;
}