@use "sass:math"; // Import nécessaire pour utiliser math.div()

$SEPARATOR_VALUE_START: '\\(';
$SEPARATOR_VALUE_END: '\\)';

/*Mixins*/
@mixin font-normal($fontWeight, $fontColor) {
    color: $fontColor !important;
    font-family: var(--primary-font-family) !important; //overwrite ng-ant font
    font-size: 14px !important; //overwrite ng-ant font
    line-height: 1.6;
    letter-spacing: 0;
    font-weight: $fontWeight;
}

@mixin font-bold($fontColor) {
    color: $fontColor !important;
    font-family: var(--bold-font-family) !important; //overwrite ng-ant font
    font-size: 14px !important; //overwrite ng-ant font
    line-height: 1.6;
    letter-spacing: 0;
}

@mixin font-medium($fontWeight, $fontColor) {
    color: $fontColor !important;
    font-family: var(--primary-font-family) !important; //overwrite ng-ant font
    font-size: 20px !important; //overwrite ng-ant font
    font-weight: $fontWeight;
}

@mixin font-large($fontWeight, $fontColor) {
    color: $fontColor !important;
    font-family: var(--primary-font-family) !important;
    font-size: 32px !important; //overwrite ng-ant
    font-weight: $fontWeight;
}

@mixin font-larger($fontWeight, $fontColor) {
    color: $fontColor !important;
    font-family: var(--bold-font-family);
    font-size: 54px !important; //overwrite ng-ant
    font-weight: $fontWeight;
}

@mixin font-small($fontWeight, $fontColor) {
    color: $fontColor !important;
    font-family: var(--primary-font-family) !important;
    font-size: 13px !important; //overwrite ng-ant
    font-weight: $fontWeight;
}

@mixin font-smaller($fontWeight, $fontColor) {
    color: $fontColor !important;
    font-family: var(--primary-font-family) !important;
    font-size: 12px !important; //overwrite ng-ant
    font-weight: $fontWeight;
}

@mixin btn-base {
    //Generic code for buttons
    background: var(--primary-color);
    border-radius: 4px;
    border: none;
    max-width: 320px;
    text-align: center;
    transition: all 0.25s ease-in-out;
    cursor: pointer;
    outline: none;
    line-height: 1em;

    &:hover:not([disabled]) {
        background: var(--primary-color-darkest);
        color: var(--lightest-color);
    }

    &:active:not([disabled]) {
        background: var(--primary-color-darkest);
    }

    &:focus:not([disabled]) {
        background: var(--primary-color);
        opacity: 0.8;
    }
}

@mixin btn-default {
    //Default button
    @include btn-base();
    @include font-bold(var(--lightest-color));
    height: 37px;
    padding: 10.5px 10px;
    font-weight: 700;
    font-size: 16px;
}

@mixin btn-small {
    //Small button for mobile
    @include btn-base();
    @include font-small(bold, var(--lightest-color));

    font-family: var(--bold-font-family) !important;
    padding: 8.5px 10px;
}

@mixin btn-larger {
    //Large button
    @include btn-base();
    @include font-bold(var(--lightest-color));
    padding: 15px 10px;
}

@mixin breakpoint($class) {
    @if $class ==xs {

        // Small devices (landscape phones, 576px and up)
        @media (min-width: 576px) {
            @content;
        }
    }

    @else if $class ==sm {

        // Medium devices (tablets, 768px and up)
        @media (min-width: 768px) {
            @content;
        }
    }

    @else if $class ==md {

        // Large devices (desktops, 992px and up)
        @media (min-width: 992px) {
            @content;
        }
    }

    @else if $class ==lg {

        // Extra large devices (large desktops, 1200px and up)
        @media (min-width: 1200px) {
            @content;
        }
    }

    @else {
        @warn "Breakpoint mixin supports: xs, sm, md, lg";
    }
}

@mixin opacity {
    @for $i from 0 through 10 {
        $opacity: if($i > 0, math.div($i, 10), 0);

        .u-opacity#{$SEPARATOR_VALUE_START}#{$i}#{$SEPARATOR_VALUE_END} {
            opacity: $opacity !important;
        }
    }
}

@mixin gaps {
    $gaps: (
        xxs: .5rem,
        xs: 1rem,
        sm: 1.6rem,
        md: 2rem,
        l: 3rem,
        xl: 5rem
    );

@each $key,
$value in $gaps {
    .u-gap#{$SEPARATOR_VALUE_START}#{$key}#{$SEPARATOR_VALUE_END} {
        gap: $value !important;
    }
}
}