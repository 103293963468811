//Base CSS generic for whole application
html,
body {
    height: 100%;
    -webkit-font-smoothing: antialiased !important;
}

body {
    background: $background-lighter-gray !important;
    margin: auto;
    @include font-normal(normal, $base-color);
    line-height: 1.5;
    letter-spacing: 0;
    font-variant-numeric: proportional-nums;
}

h1 {
    @include font-larger(bold, $base-color);
    font-family: $bold-font-family !important;
    -webkit-font-smoothing: antialiased !important;
}

h2 {
    @include font-large(bold, $base-color);
    font-family: $bold-font-family !important;
    -webkit-font-smoothing: antialiased !important;
}

h3 {
    @include font-medium(bold, $base-color);
    font-family: $bold-font-family !important;
    -webkit-font-smoothing: antialiased !important;
}

h4 {
    @include font-small(bold, $base-color);
    font-family: $bold-font-family !important;
    -webkit-font-smoothing: antialiased !important;
}

strong {
    font-family: $bold-font-family !important;
    -webkit-font-smoothing: antialiased !important;
}

button,
input,
select,
textarea,
ul {
    @include font-normal(normal, $base-color);
}

a {
    color: $base-color;

    &:hover {
        color: $primary-color;
    }
}

p {
    @include font-normal(normal, $base-color);
}

// Layout style
.app-container {
    min-height: 650px;
}

.page-template {
    &-head {
        min-height: 230px;
        position: relative;
        margin-bottom: 50px;
        background-color: #ecebe7;
        -webkit-font-smoothing: antialiased !important;

        .container {
            padding-top: 105px;
        }
    }

    &-labels {
        font-size: 12px !important;
        color: $neutral-darker;
        font-family: $bold-font-family !important;

        &--black {
            color: $base-color;
        }
    }

    &-text {
        @include font-normal(normal, $base-color);

        &--smaller {
            @include font-smaller(normal, $neutral-darker);
        }

        &--small {
            @include font-small(normal, $base-color);
        }
    }

    &-link {
        color: $primary-color;

        &:hover {
            color: $primary-color-darkest;
        }
    }
}

.item-card-grid {
    display: grid;
    grid-gap: 0px;
    margin-left: -10px;
    margin-right: -10px;

    @include breakpoint(sm) {
        //padding: 7.5px;
        grid-template-columns: repeat(2, 50%);
    }

    @include breakpoint(md) {
        grid-template-columns: repeat(4, 25%);
    }
}

.text-highlight {
    padding: 5px 10px;
    word-break: keep-all;
    font-size: 13px;
    background-color: $neutral-dark;
}

.dark-text-align {
    padding: 5px 10px 5px 0px;
    word-break: break-word;
    font-size: 13px;
    box-shadow: -10px 0px 0px 0px #e5e3df;
    background-color: #e5e3df;
    border-radius: 4px;
}

.item-card-wrapper {
    padding: 10px;
}

// Position
.position-relative {
    position: relative !important;
    background-color: $background-lighter-gray;
}

// Quick access to set text colors
.clr-white {
    color: white !important;
}

.clr-neutral {
    color: $neutral-dark;
}

.clr-neutral-light {
    color: $neutral;
}

.clr-primary {
    color: $primary-color !important;
}

.clr-green {
    background: $success-color;
}

.clr-red {
    color: $error-color !important;
}

.clr-black {
    color: black !important;
}

// Quick access to set background colors
.bg-neutral {
    background: $neutral-dark;
}

.bg-neutral-light {
    background: $neutral;
}

.bg-primary {
    background: $primary-color;
}

.bg-green {
    background: $success-color;
}

.bg-red {
    background: $error-color;
}

.bg-gray {
    background: $background-light-gray;
}

input,
input.ant-input,
select,
input.ant-time-picker-input {
    background: $lightest-color;
    border: 0px solid #e5e3df !important; //overwrite ng-ant
    border-radius: 4px !important; //overwrite ng-ant
    height: $input-small-height !important;
    line-height: 1.4;
    padding-left: 10px;
    transition: all 0.25s ease-in-out;
    width: 100%;

    @include breakpoint(md) {
        height: $input-default-height;
    }

    &:not(.ant-select-selection-search-input):not(.ant-picker-input input):not(.stand-search__input):focus {
        border: 2px solid $primary-color-dark !important; //overwrite ng-ant
        box-shadow: 0 0 0 2px rgba(23, 2, 248, 0.2) !important;
        outline: none !important;
    }

    &:disabled {
        border: none;
    }

    &.nz-select {
        border-width: 2px !important;
    }
}

input.ant-input,
.ant-form-item-control-input>input,
.ant-form-item-control-input>select {
    border-width: 2px !important;
}

textarea {
    background: $lightest-color;
    border: 2px solid $neutral-dark; //overwrite ng-ant
    border-radius: 4px !important; //overwrite ng-ant
    padding-left: 11px;
    transition: all 0.25s ease-in-out;

    &:focus {
        border: 2px solid $primary-color-dark !important; //overwrite ng-ant
        box-shadow: 0 0 0 2px rgba(23, 2, 248, 0.2) !important;
        outline: none !important;
    }

    &:disabled {
        border: none;
    }
}

.border-left {
    border-left: 1px solid $neutral-dark;
}

.border-right {
    border-right: 1px solid $neutral-dark;
}

.ant-cascader-picker:focus .ant-cascader-input,
.ant-select-open .ant-select-selection:focus {
    border: 2px solid #e5e3df !important; //overwrite ng-ant
    box-shadow: 0 0 0 2px rgba(23, 2, 248, 0.2) !important;
    outline: none !important;
}

.input-small-description {
    margin-top: 10px;
    margin-bottom: 0;
    font-style: italic;
    line-height: 1.3;
    color: $neutral-darkest;
    font-size: 13px !important;
}

.input-radioBtn {
    display: flex;
    margin: 0 auto 15px !important;

    &__radio {
        font-family: $bold-font-family !important;
        color: $base-color;
    }

    .ant-radio-checked .ant-radio-inner {
        border-color: $primary-color;

        &::after {
            background: $primary-color;
        }
    }
}

.list-filters {
    padding: 0px 8px 0px 12px !important;
    // background: #ffffff;
    border-radius: 5px;
    min-width: 23%;

    &--left {
        width: 25%;
    }

    &__box {
        border-radius: 5px;
        padding: 0;
        background: #ffffff;
        margin-bottom: 4px;

        &__title {
            //font-weight: 700;
            display: block;
            font-size: 14px !important;
            color: $neutral-darker !important;
            padding: 5px 15px 10px !important;
        }
    }

    &__item {
        color: $primary-color;
        font-size: 13px;
        padding: 5px 15px;

        &:hover {
            color: $primary-color-dark;
        }

        &.ant-menu-item-selected,
        &.active {
            color: #fff !important;
            background: $primary-color !important;
        }
    }
}

.form-filter {
    margin-top: 15px;
    border: 2px solid $neutral-dark;
    border-radius: 6px;
    width: 100%;
    background: $lightest-color;

    &__container {
        position: relative;

        &:before {
            background: $lightest-color;
            border: 2px solid $lightest-color;
            border-left-color: $neutral-dark;
            border-top-color: $neutral-dark;
            content: '';
            display: block;
            height: 20px;
            position: absolute;
            right: 100px;
            top: -10px;
            transform: rotate(45deg);
            width: 20px;
        }
    }

    &__footer {
        border-top: 2px solid $neutral-dark;
        background: #faf9f7;
    }

    &--right {
        .form-filter__container {
            &:before {
                right: 40px;
            }
        }
    }
}

.filter-button {
    border: 2px solid #e5e3df;
    height: 45px;
    width: 58px;
    background-color: #fff;
    border-radius: 4px 0 0 4px;
    cursor: pointer;
    outline: none;

    &.mr-2 {
        border-radius: 0 4px 4px 0;
    }

    &.active {
        background: $primary-color;
        border-color: $primary-color;
        color: $lightest-color !important;
    }

    span {
        pointer-events: none;
    }

    i {
        position: relative;
        pointer-events: none;
        top: 3px;
        font-size: 20px;
    }
}

.item-row {
    display: flex;
    align-items: center;
    transition: 0.25s ease-in-out all;
    position: relative;
    padding: 0px;
    width: 100%;

    &--light {
        background: $lightest-color;
        transition: 0.25s ease-in-out all;
    }

    &--light:hover {
        background: $neutral-dark;
    }

    &__col {
        display: inline-block;
        flex-basis: 100%;
        flex-grow: 0;
    }

    &__avatar-container {
        display: flex;
        height: auto;
        overflow: hidden;
        width: 75px;
    }

    &__col--avatar {
        flex-basis: auto;
        padding: 0px;
        padding-right: 10px;
    }

    &__col--auto {
        flex-basis: auto;
    }

    &__text {
        padding: 0px !important;
        margin: 0px !important;
        @include font-small(normal, $neutral-darkest);

        &--title {
            @include font-bold($base-color);
        }

        &--subtitle {
            @include font-small(normal, $base-color);
        }
    }

    &__avatar {
        &-src {
            max-width: 67px;
            max-height: 67px;
        }
    }
}

label,
label span.ng-checkbox-text {
    @include font-small(bold, $base-color);
    font-family: $bold-font-family !important;
}

select {
    background: url('/assets/images/icon-chevron-down.svg') 94% 7px no-repeat #fff;

    @include breakpoint(md) {
        background-position: 94% 11px;
    }

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-padding-end: 33px !important;
}

.select {
    &--small {
        height: $input-small-height;

        .ant-select-selection--single {
            min-height: $input-small-height !important;
        }

        .ant-select-selection-selected-value {
            line-height: 33px;
        }

        .ant-select-search__field__placeholder,
        .ant-select-selection__placeholder {
            line-height: 24px;
        }
    }
}

.fiche-content .ql-container.ql-disabled {
    border: none;

    .ql-editor {
        padding: 15px 0px;
        margin: 0px 0px 30px;

        p {
            color: #1c1c1b;
            line-height: 24px;
            font-size: 16px !important;
        }
    }
}

.modal {
    display: block;
    background-color: rgba(0, 0, 0, 0.65);
    height: 100%;
    left: 0;
    overflow: auto;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;

    &__container {
        align-items: center;
        background-color: $lightest-color;
        border: 2px solid $neutral-dark;
        border-radius: 6px;
        margin: 15% auto;
        /* 15% from the top and centered */
        width: 500px;
        /* Could be more or less, depending on screen size */
    }

    &__content {
        display: flex;
        position: relative;
        padding: 30px;

        &-text {
            @include font-bold($base-color);
            margin: 0px;
        }

        &-icon {
            align-items: center;
            color: $primary-color;
            display: flex;
            font-size: 32px;
            margin-right: 30px;
        }
    }

    &__footer {
        background: $neutral-light;
        border-top: 2px solid $neutral-dark;
        display: flex;
        justify-content: flex-end;
        padding: 15px;
    }

    &__close {
        background: none;
        border: none;
        float: right;
        font-size: 20px;
        outline: none;
        position: absolute;
        cursor: pointer;
        right: 7.5px;
        top: 10px;

        &:hover i {
            color: $primary-color;
        }

        i {
            color: $base-color;
            font-size: 25px;
        }
    }
}

.btn-icon {
    font-size: 20px;
    cursor: pointer;
}

.btn-input-upload {
    border: 2px solid var(--neutral-dark);
    border-radius: 4px;
    color: var(--base-color) !important;
    padding: 0 7px;

    &:hover {
        color: var(--base-color) !important;
        border-color: var(--base-color);
    }

    .ant-upload-disabled & {
        cursor: not-allowed;
        pointer-events: none;
        color: lighten($base-color, 50%) !important;

        &:hover {
            border: 2px solid var(--neutral-dark);
            background-color: initial;
        }
    }
}

.btn {
    padding: 13px 22px;
    line-height: 1 !important;
}

.btn--default,
.btn--small {
    font-family: $bold-font-family !important;
}

.btn--edit {
    display: flex;
    align-items: center;
}

.btn--light {
    font-size: 13px;
}

.btn--large {
    height: 45px !important;
}

.btn-square {
    border: 2px solid $neutral-dark;
    border-radius: 4px;
    cursor: pointer;
    height: 38px;
    width: 38px;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        border-color: $primary-color;
    }

    i {
        font-size: 20px;
    }
}

.btn-rect {
    --height: 40px;
    color: var(--base-color) !important;
    background: var(--lightest-color);
    border: 2px solid var(--dark-grey);
    padding: 7px 10px;
    border-radius: 4px;
    cursor: pointer;
    height: var(--height);
    outline: none;
    transition: border-color 0.15s ease-in-out;
    font-weight: 700;

    &:hover {
        color: $base-color;
        border-color: var(--grey-700);
    }

    i {
        font-size: 20px;
        position: relative;
        top: 3px;
    }

    &.btn--large {
        --height: 45px;
        display: flex;
        align-items: center;
    }
}

.searchbar {
    span {
        right: 0 !important; //overwrite ng-ant
    }

    &__button {
        background: $primary-color;
        border: none;
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
        cursor: pointer;
        display: flex;
        flex: 1;
        height: $input-small-height;
        position: relative;
        right: 1px;
        top: 0px;
        width: 38px;

        @include breakpoint(md) {
            top: -1px;
            height: 44px;
            width: 45px;
        }

        i {
            margin: 0 auto;
            color: $lightest-color;
        }
    }

    .searchBarInput {
        padding-left: 15px;
        padding-right: 55px;
    }

    .ant-select-selection__rendered {
        padding-left: 35px;
        padding-right: 15px;
    }

    .ant-select-selection__clear {
        margin-right: 10px;
        font-size: 16px !important;
        top: 47%;
    }

    span.ant-select-arrow {
        position: absolute;
        left: 10px !important;
        right: unset !important;
        font-size: large;
        top: 45%;
    }

    span.ant-input-suffix {
        position: absolute;
        left: 10px !important;
        right: unset !important;
        font-size: large;
        top: 52%;
        transform: translateY(-50%);
    }
}

.opacity50 {
    opacity: 0.5;
    transition: 0.15s ease-in-out all;
}

.fw-normal {
    font-weight: normal !important;
}

.v-align-top {
    vertical-align: top;
}

.v-align-center {
    vertical-align: middle;
}

.icon-for-btn {
    margin-right: 5px;
    position: relative;
    top: 1px;
}

.rotate-180 {
    transform: rotate(180deg);
    transition: 0.25s ease-in-out all;
}

.rotate-init {
    transition: 0.25s ease-in-out all;
}

.form-state {
    //box showing state of form
    border: 1px solid #c7c7c7;
    color: #b9b9b9;
    padding: 5px 10px;
}

.big-title {
    font-size: 50px;
}

/*animations*/
.fadeIn {
    animation: fadeIn 1s;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.rotateInfinite {
    -webkit-animation: rotating 2s linear infinite;
}

@keyframes rotating {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}

.pointer {
    cursor: pointer;
}

.layout--flexrow {
    direction: horizontal;
    display: flex;
    justify-content: space-between;
}

.sidenav-container {
    margin-bottom: 50px;

    .section--title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 15px;
        border-bottom: 2px solid #ecebe7;
        background: #faf9f7;
        border-radius: 5px 5px 0px 0px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        background: white;

        &__links {
            color: $primary-color;
            line-height: 1.5;
        }

        &__sub {
            display: contents;
        }

        .footer-link {
            background-color: $neutral;
        }
    }

    &__list {
        .sidebar-image-container {
            display: flex;
            min-height: 65px;
            align-items: center;
        }

        .ant-list-item {
            padding: 0px;
            border-bottom: 1px solid #ecebe7;

            &:last-of-type {
                border-bottom: none;
            }
        }

        .ant-list-footer {
            color: $primary-color;
            background: #f4f3ef;
        }
    }
}

.status-bulb {
    border-radius: 50%;
    text-align: center;
    display: block;
    align-items: center;

    &--small {
        width: 18px;
        height: 18px;
        font-size: smaller;
    }

    &--success {
        color: $success-color;
        background-color: $success-color-background;
    }

    &--pending {
        color: $neutral-darkest;
        background: $neutral-dark;
    }

    &--warning {
        color: $warning-color;
        background-color: $warning-color-background;
    }

    &--errors {
        color: $error-color;
        background-color: $error-color-background;
    }
}

.txt-underline {
    text-decoration: underline;
}

/*
* Theme
* Overwrites from NG Ant Components classes
*/
.table {
    background: $lightest-color;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border-collapse: collapse;
    max-width: 100%;
    // overflow-x: auto;
    width: 100%;

    &--no-white {
        background: none !important;
    }

    div {
        &:last-child {
            margin-bottom: 0px;
        }
    }

    &:last-child {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }

    .table--neutral & {
        &__title {
            @include font-bold($base-color);
        }

        &__type--missing {
            opacity: 0.3;
        }
    }

    td {
        @include font-normal(normal, $neutral-darkest);
        vertical-align: middle;
        white-space: nowrap;
    }

    &__avatar-td {
        padding: 0px !important; //overwrite ng-ant
        padding-right: 10px !important;
        width: 75px;
    }

    &__avatar-container {
        display: flex;
        height: 75px;
        overflow: hidden;
        width: 75px;
    }

    &__avatar {
        height: 75px;
        width: auto;
    }

    &__title {
        //Repertory title
        @include font-bold($primary-color);
    }

    &__type {
        @include font-small(normal, $base-color);
    }

    &__email {
        //Repertory email
        text-decoration: underline;
    }

    p {
        margin: 0px;
        padding: 1px;
    }

    tr {
        border-bottom: 2px solid $lightest-color;
        margin-bottom: 15px;
        padding-bottom: 15px;
    }
}

.align-items-center {
    align-items: center;
}

/*Info Steps Show*/
.info-steps {
    border-radius: 4px;
    background: $lightest-color;
    padding: 15px;

    &__number {
        position: relative;
        display: inline-block;
        font-size: 30px;
        border-radius: 50%;
        border: 1px solid $neutral-darkest;
        padding: 20px;
        color: $neutral-darkest;
        font-family: $bold-font-family !important;
        text-align: center;
        width: 50px;
        height: 50px;

        span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &__texts {
        display: inline-block;
    }

    &__title {
        font-family: $bold-font-family !important;
    }
}

// Multimedia players
.noPlayerBox {
    position: relative;

    a {
        @include font-bold($base-color);
        background-color: white;
        padding: 30px;
        display: block;

        &:hover {
            background-color: $neutral-darker;
        }
    }
}

.approved {
    background-color: #00b899 !important;
}

.rejected {
    background-color: #fe544d !important;
}

.pr-12,
.px-12 {
    padding-right: 12px !important;
}

.help-icon {
    display: inline-flex;
    height: 20px;
    width: 20px;
    background-image: url('/assets/images/icon-question-mark.svg');
    background-position: center;
    background-repeat: no-repeat;

    &--white {
        background-image: url('/assets/images/icon-question-mark-white.svg');
    }

    &--blue {
        background-image: url('/assets/images/icon-question-mark-blue.svg');
    }

    &--small {
        background-size: 75%;
    }
}

app-stand-list {
    width: 100%;
}

.input {
    border: 0px solid #e5e3df !important;
    border-radius: 4px !important;
}

.searchbar__btn {
    border-radius: 0 4px 4px 0;
    height: 41px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.table-head {
    background: $neutral-light !important;
    font-family: $bold-font-family;
    border-radius: 5px;
}

.search-advanced-link {
    @include font-small(bold, $primary-color);
    font-family: $bold-font-family !important;
    display: flex;
    align-items: baseline;

    &:hover {
        text-decoration: none;
    }

    span {
        border-bottom: 1px solid $primary-color;
    }

    svg {
        fill: $primary-color;
        position: relative;
        top: 6px;
        width: 18px;
    }
}

.input-border {
    border: 2px solid #e5e3df !important;
}

p.disabled {
    pointer-events: none;
    opacity: 0.6;
    cursor: not-allowed;
}